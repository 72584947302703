import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, useAuth, useSiedeBar } from 'contexts';
import brandImg from 'assets/brand.png';
import avartarImgDefault from 'assets/avatar.png';
import * as S from './styles';

export const Header: React.FC = () => {
  const { signOut, user } = useAuth();
  const history = useHistory();
  const { onToogleOpenSideBar } = useSiedeBar();
  const { theme, onChangeTheme } = useTheme();

  const avatarImg = user.avatar_url ? user.avatar_url : avartarImgDefault;

  const handleToogleMenu = useCallback(() => {
    onToogleOpenSideBar();
  }, [onToogleOpenSideBar]);

  const handleSetTheme = useCallback(
    (themeSelected: string) => {
      onChangeTheme(themeSelected);
    },
    [onChangeTheme],
  );

  const navegateToProfile = useCallback(() => {
    history.push('/profile');
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      <S.WrapperLeft>
        <S.IconMenu onClick={handleToogleMenu} size={24} />
        <S.Brand width="24" height="24" src={brandImg} />
        <S.Title>Exalte Lingerie</S.Title>
      </S.WrapperLeft>

      <S.WrapperRight>
        {theme === 'dark' && (
          <S.IconDarkTheme size={24} onClick={() => handleSetTheme('light')} />
        )}
        {theme === 'light' && (
          <S.IconLightTheme size={24} onClick={() => handleSetTheme('dark')} />
        )}
        <S.IconNotifications size={24} />

        <S.AvatarInfo>
          <S.Avatar src={avatarImg} role="button" onClick={navegateToProfile} />
          <S.UserInfo>
            <S.UserName>{user.name}</S.UserName>
          </S.UserInfo>
        </S.AvatarInfo>
        <S.IconSettings onClick={signOut} size={24} />
      </S.WrapperRight>
    </S.Container>
  );
};
